import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../../Atoms/QuoteMark/QuoteMark';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import CommitteeDetail from '../../../../components/ContentType/CommitteeDetail/CommitteeDetail';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import ListDirectionMember from '../../../../components/ContentType/ListDirectionMember/ListDirectionMember';
import ListAdminMembers from '../../../../components/ListAdminMembers/ListAdminMembers';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import Layout from '../../../../Layout';
import { Button } from '../../../../Molecules/Button/Button';
import DocumentView from '../../../../Molecules/DocumentView/DocumentView';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import { getImage, getDocument, formatBytes, getBreadCrumb } from '../../../../Utils/Utils';
import './styles.scss';

let classNames = require('classnames');

const GovernancePage = ({ data }) => {
  const intl = useIntl();
  const imagesArray = data?.allImages?.edges;
  const documentsArray = data.allDocuments.edges;
  const governanceData = data?.governanceData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, governanceData.path?.alias);

  const [innerNavList, setInnerNavList] = useState([]);
  const [isBlockBannerAvail, setIsBlockBannerAvail] = useState(false);

  let list_board_of_directors = [];
  let sectionArr = [];
  let sectionCount = 0;
  let isBanner = false;

  useEffect(() => {
    setInnerNavList(sectionArr);
    setIsBlockBannerAvail(isBanner);
  }, [])

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={governanceData?.title}
        description={governanceData?.body?.processed}
      />
      <div className={classNames('page_template', 'page_gouvernance')}>
        <ScrollPageNav
          data={innerNavList}
        />
        {!isBlockBannerAvail && <PageBanner visuel={getImage(imagesArray, governanceData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                locale: governanceData.langcode
              }}
            />

            <TitlePage
              color="color_white"
              title={governanceData?.title}
              description={governanceData?.body?.processed}
            />
          </div>
        </PageBanner>}
        {governanceData?.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__banner':
              isBanner = true;

              return (
                <PageBannerStyle
                  visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}
                  breadcrumbData={{

                    gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                    parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                    currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                    locale: governanceData.langcode
                  }}
                >
                  <div className="wrapper_page">
                    <TitlePage
                      color="color_white"
                      title={governanceData?.title}
                    />
                    <div dangerouslySetInnerHTML={{ __html: block.body?.processed }} />
                  </div>
                </PageBannerStyle>
              );

            case 'block_content__block_texte':
              sectionCount++;
              sectionArr.push({
                title: '0' + sectionCount + '. ' + block.field_title?.processed,
                section: 'scroll_to_' + sectionCount
              });
              return (
                <IntroPage
                  key={i}
                  customClass={"topZIndex " + 'scroll_to_' + sectionCount}
                  title={block.field_title?.processed}
                  subtitle={block.field_subtitle?.processed}
                  size="border" //wrapper
                  text={block.body?.processed}
                />
              );

            case 'block_content__block_conseil_administration':
              block.relationships?.field_biographies?.forEach((biography) => {
                list_board_of_directors.push(
                  {
                    visuel: getImage(imagesArray, biography?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid,
                    fullname: biography.title,
                    post: biography.field_poste,
                    link: '/' + biography.path?.langcode + biography.path?.alias
                  }
                );
              });
              return (
                <section className={classNames('section_content', 'section_board_of_directors')} key={i}>
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={block.field_title?.processed}
                      description={block.field_subtitle?.processed}
                    />
                  </div>
                  <div className="wrapper_page">

                    <ListAdminMembers
                      listAdmins={list_board_of_directors}
                    />

                  </div>
                </section>
              );

            case 'block_content__block_citation':
              return (
                <section className={classNames('section_content', 'section_board_of_directors')} key={i}>
                  <div className="quote_container">
                    <QuoteMark
                      lang={block.langcode}
                      text={block.field_citation}
                    />
                  </div>
                </section>
              );

            case 'block_content__block_document':
              let docArr = [];
              block.relationships?.field_file?.forEach((file => {
                docArr.push(
                  {
                    name: getDocument(documentsArray, file?.drupal_internal__mid)?.field_media_document?.description,
                    size: `(.pdf ${formatBytes(getDocument(documentsArray, file?.drupal_internal__mid)?.relationships?.field_media_document?.localFile.size)})`,
                    link: getDocument(documentsArray, file?.drupal_internal__mid)?.relationships?.field_media_document?.localFile.publicURL
                  }
                )
              }));
              return (
                < section className={classNames('section_content')} key={i}>
                  <div className="wrapper_page">
                    <DocumentView
                      data={docArr}

                    />
                  </div>
                </section>
              );

            case 'block_content__block_gouvernance':

              return (
                <section className={classNames('section_content', 'section_list_committee')} key={i}>
                  <ShapeBackground
                    top="left"
                    color="catskill_white"
                    bottomInfinite={true}
                  >
                    <div className="wrapper_page">
                      {block.relationships?.field_blocs?.map((compose, l) => {
                        return (
                          <CommitteeDetail
                            key={l}
                            title={compose.field_title?.processed}
                            description={compose.field_bloc_text_1.processed}
                            composeTxt={compose.field_bloc_text_2.processed}
                          />
                        );
                      })}
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_direction':
              let directionMembers = [];
              block.relationships?.field_biographies?.forEach((biography => {

                directionMembers.push(
                  {
                    visuel: getImage(imagesArray, biography?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp,
                    name: biography.title,
                    post: biography.field_poste,
                    dob: biography.field_date_de_naissance,
                    textDetails: (biography.body?.summary != '' && biography.body?.summary != undefined) ? '<p>' + biography.body?.summary + '</p>' : biography.body?.processed
                  }
                )
              }));
              sectionCount++;
              sectionArr.push({
                title: '0' + sectionCount + '. ' + block.field_title?.processed,
                section: 'scroll_to_' + sectionCount
              });
              return (
                <section className={classNames('section_content', 'section_direction', 'scroll_to_' + sectionCount)} key={i}>
                  <ShapeBackground
                    top="left"
                    bottom="left"
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        h2color="white"
                      />
                    </div>
                    <div className="wrapper_page">
                      <div className="btn_container">
                        {block.field_lien_multiple?.map((button, j) => {
                          return (
                            <Button
                              key={j}
                              label={button.title}
                              link={button.url}
                            />
                          );
                        })}
                      </div>
                    </div>
                    <ListDirectionMember
                      listDirectionMember={directionMembers}
                      textColor='color_white'
                    />
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_document_avec_titre':
              let allDocDetails = [];

              block.relationships?.field_document_block?.forEach((doc => {
                let filesArr = [];
                doc.relationships?.field_file?.forEach((file => {
                  filesArr.push(
                    {
                      name: getDocument(documentsArray, file?.drupal_internal__mid)?.field_media_document?.description,
                      size: `(.pdf ${formatBytes(getDocument(documentsArray, file?.drupal_internal__mid)?.relationships?.field_media_document?.localFile.size)})`,
                      link: getDocument(documentsArray, file?.drupal_internal__mid)?.relationships?.field_media_document?.localFile.publicURL
                    }
                  )
                }));

                allDocDetails.push(
                  {
                    title: doc.field_title?.processed,
                    subTitle: doc.field_subtitle?.processed,
                    linkTitle: doc.field_lien?.title,
                    link: doc.field_lien?.require,
                    files: filesArr
                  }
                )
              }));
              return (
                <section className={classNames('section_content', 'section_documents')}>
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={block.field_title?.processed}
                    />
                    {allDocDetails.map((document, k) => {
                      sectionCount++;
                      sectionArr.push({
                        title: '0' + sectionCount + '. ' + document.title,
                        section: 'scroll_to_' + sectionCount
                      });
                      return (
                        <div className={classNames('document_type', 'scroll_to_' + sectionCount)} key={k}>
                          <TitleBlock
                            title={document.title}
                          />
                          <div dangerouslySetInnerHTML={{ __html: document.subTitle }}></div>
                          {document.linkTitle && <Button
                            label={document.linkTitle}
                            link={document.link}
                          />}
                          <DocumentView
                            data={document.files}
                          />
                        </div>
                      );
                    })}
                  </div>
                </section>
              );
          }
        })}
      </div>
    </Layout >
  );
};

export const query = graphql`
query governanceTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!], $documentsID: [Int!]) {
  governanceData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        langcode
        field_type
        path {
          langcode
          alias
        }
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__banner {
                body {
                  processed
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_texte {
                field_title {
                  processed
                }
                body {
                  processed
                }
                field_subtitle {
                  processed
                }
              }
              ... on block_content__block_conseil_administration {
                field_title {
                  processed
                }
                field_subtitle {
                  processed
                }
                relationships {
                  field_biographies {
                    body {
                      processed
                    }
                    field_poste
                    field_date_de_naissance
                    title
                    path {
                      langcode
                      alias
                    }
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                    }
                  }
                }
              }
              ... on block_content__block_citation {
                field_citation
                langcode
              }
              ... on block_content__block_document {
                relationships {
                  field_file {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_gouvernance {
                relationships {
                  field_blocs {
                    field_bloc_text_1 {
                      processed
                    }
                    field_bloc_text_2 {
                      processed
                    }
                    field_title {
                      processed
                    }
                  }
                }
              }
              ... on block_content__block_direction {
                field_lien_multiple {
                  url
                  title
                }
                field_title {
                  processed
                }
                relationships {
                  field_biographies {
                    body {
                      processed
                      summary
                    }
                    field_poste
                    field_date_de_naissance
                    title
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                    }
                  }
                }
              }
              ... on block_content__block_document_avec_titre {
                field_title {
                  processed
                }
                relationships {
                  field_document_block {
                    field_title {
                      processed
                    }
                    field_subtitle {
                      processed
                    }
                    field_lien {
                      title
                      url
                    }
                    relationships {
                      field_file {
                        drupal_internal__mid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          processed
        }
      }
    }
  }

  allDocuments: allMediaDocument(filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $documentsID}}) {
    edges {
      node {
        langcode
        field_media_document {
          description
        }
        relationships {
          field_media_document {
            localFile {
              publicURL
              size
              name
            }
          }
        }
        drupal_internal__mid
      }
    }
  }
  
  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 332, height: 332) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }
  allMenu :   allMenuLinkContentMenuLinkContent(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        drupal_parent_menu_item
        drupal_id
        langcode
        link {
          url
        }
        menu_name
        title
      }
    }
  }
}
`;

export default GovernancePage;
